import { EmployerProvider } from "./EmployerProvider";
import { Employer } from "./Employer";

export class InMemoryEmployerProvider implements EmployerProvider {
  private records: Record<string, Employer> = {};

  public add(record: Employer): this {
    this.records[record.id] = record;
    return this;
  }

  public addMany(records: Employer[]): this {
    for (const record of records) {
      this.add(record);
    }
    return this;
  }

  public async getById(id: string): Promise<Employer> {
    if (Object.prototype.hasOwnProperty.call(this.records, id)) {
      return this.records[id];
    }
    throw new Error(`Unknown employer '${id}'.`);
  }
}
