import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

const elements = document.querySelectorAll('[data-app="calculator"]');
for (const element of elements) {
  ReactDOM.createRoot(element as HTMLElement).render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
  );
}
