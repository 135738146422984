import { InMemoryWorkShiftProvider } from "../InMemoryWorkShiftProvider";
import { InMemoryEmployerProvider } from "../InMemoryEmployerProvider";
import { InMemoryEmployeeProvider } from "../InMemoryEmployeeProvider";
import { InMemorySalaryAdvanceProvider } from "./InMemorySalaryAdvanceProvider";
import { Employee } from "../Employee";
import { Employer } from "../Employer";
import { WorkShift } from "../WorkShift";
import { InMemoryCzechDocumentProvider } from "./InMemoryCzechDocumentProvider";
import { CzechDocument } from "./CzechDocument";
import moment, { Moment } from "moment-timezone";
import { newMoment, timezone } from "./MomentTestSupport";
import { InMemoryCzechContractProvider } from "./InMemoryCzechContractProvider";
import { CzechContract } from "./CzechContract";
import { CzechSalaryAdvance } from "./CzechSalaryAdvance";
import { CzechSalaryType } from "./CzechSalaryType";
import { CzechAvailableContract } from "./CzechAvailableContract";
import { WorkShiftType } from "../WorkShiftType";
import { WorkShiftStatus } from "../WorkShiftStatus";

export class CzechTestProviders {
  public readonly employee: InMemoryEmployeeProvider;
  public readonly employer: InMemoryEmployerProvider;
  public readonly salaryAdvance: InMemorySalaryAdvanceProvider;
  public readonly workShift: InMemoryWorkShiftProvider;
  public readonly document: InMemoryCzechDocumentProvider;
  public readonly contract: InMemoryCzechContractProvider;

  public constructor() {
    this.employee = new InMemoryEmployeeProvider();
    this.employer = new InMemoryEmployerProvider();
    this.salaryAdvance = new InMemorySalaryAdvanceProvider();
    this.workShift = new InMemoryWorkShiftProvider();
    this.document = new InMemoryCzechDocumentProvider();
    this.contract = new InMemoryCzechContractProvider();
  }

  public addEmployee(record: Employee): this {
    this.employee.add(record);
    return this;
  }

  public addEmployer(record: Employer): this {
    this.employer.add(record);
    return this;
  }

  public addDocuments(contractId: string, documents: Omit<CzechDocument, 'employeeId'|'employerId'>[]): this {
    const contract = this.contract.getByIdSync(contractId);
    for (const document of documents) {
      this.document.add({ ...document, employeeId: contract.employeeId, employerId: contract.employerId });
    }
    return this;
  }

  public addContract(contract: CzechContract): this {
    this.contract.add(contract);
    return this;
  }

  public addContracts(contracts: CzechContract[]): this {
    this.contract.addMany(contracts);
    return this;
  }

  public createAvailableContracts(contract: CzechContract): CzechAvailableContract[] {
    const lengthInDays = moment.tz(contract.expiresOn, timezone).diff(moment.tz(contract.startsOn, timezone), 'days', true);
    const available: CzechAvailableContract = {
      type: contract.type,
      templateId: contract.templateId,
      startsOn: contract.startsOn,
      computeExpiresOn: (startsOn: string) => moment.tz(startsOn, timezone).add(Math.floor(lengthInDays), 'days').endOf('day').toISOString(),
      iscoPrefixes: contract.iscoPrefixes,
      fixedValues: {},
    };
    return [ available ];
  }

  public addSalaryAdvance(contractId: string, type: CzechSalaryType, year: number, month: number, amount: number): this {
    const contract = this.contract.getByIdSync(contractId);
    const record: CzechSalaryAdvance = {
      employeeId: contract.employeeId,
      employerId: contract.employerId,
      paidOn: newMoment([year, month - 1, 14]),
      type,
      amount,
    };
    this.salaryAdvance.add(record);
    return this;
  }

  public createWorkShiftWithStringStartTime(contractId: string, planned: boolean, startTime: string, shiftLength: (startTime: Moment) => Moment, hourlyRate: number, type = WorkShiftType.Regular): WorkShift {
    const finishTime = shiftLength(moment.tz(startTime, timezone));
    const contract = this.contract.getByIdSync(contractId);
    return {
      contractId,
      employeeId: contract.employeeId,
      employerId: contract.employerId,
      finishTime: finishTime.format(),
      hourlyRate,
      iscoCode: "",
      status: planned ? WorkShiftStatus.Planned : WorkShiftStatus.Worked,
      offerId: "",
      paySupplements: [],
      startTime,
      type,
      workBreaks: [],
      workId: "",
    };
  }

  public createWorkShift(contractId: string, planned: boolean, year: number, month: number, day: number, hour: number, minute: number, shiftLength: (startTime: Moment) => Moment, hourlyRate: number, type = WorkShiftType.Regular): WorkShift {
    const startTime = newMoment([year, month - 1, day, hour, minute, 0, 0]);
    const finishTime = shiftLength(moment.tz(startTime, timezone));
    const contract = this.contract.getByIdSync(contractId);
    return {
      contractId,
      employeeId: contract.employeeId,
      employerId: contract.employerId,
      finishTime: finishTime.format(),
      hourlyRate,
      iscoCode: "",
      status: planned ? WorkShiftStatus.Planned : WorkShiftStatus.Worked,
      offerId: "",
      paySupplements: [],
      startTime,
      type,
      workBreaks: [],
      workId: "",
    };
  }

  public createAndAddWorkShift(contractId: string, planned: boolean, year: number, month: number, day: number, hour: number, minute: number, shiftLength: (startTime: Moment) => Moment, hourlyRate: number, type = WorkShiftType.Regular): WorkShift {
    const workShift = this.createWorkShift(contractId, planned, year, month, day, hour, minute, shiftLength, hourlyRate, type);
    this.workShift.add(workShift);
    return workShift;
  }

  public addWorkShift(contractId: string, planned: boolean, year: number, month: number, day: number, hour: number, minute: number, shiftLength: (startTime: Moment) => Moment, hourlyRate: number, type = WorkShiftType.Regular): this {
    this.createAndAddWorkShift(contractId, planned, year, month, day, hour, minute, shiftLength, hourlyRate, type);
    return this;
  }

  public workShiftDuration(durationInMinutes: number): (startTime: Moment) => Moment {
    return (startTime: Moment): Moment => startTime.clone().add(durationInMinutes, "minutes");
  }

  public addWorkBreakToWorkShift(workShift: WorkShift, afterMinutes = 240, durationInMinutes = 30): WorkShift {
    const startsAt = moment.tz(workShift.startTime, timezone);
    startsAt.add(afterMinutes, 'minute');
    const endsAt = startsAt.clone();
    endsAt.add(durationInMinutes, 'minute');
    workShift.workBreaks.push({
      durationInMinutes: durationInMinutes,
      startsAt: startsAt.format(),
      endsAt: endsAt.format(),
    });
    return workShift;
  }
}
