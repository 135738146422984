// Záměrně uvedeno v češtině, aby se zabránilo nedorozumění u nepřeložitelné právní terminologie.
export enum CzechDocumentType {
  Smlouva = "Smlouva",
  PotvrzeniOStudiu = "PotvrzeniOStudiu",
  ProhlaseniPoplatnika = "ProhlaseniPoplatnika",
  PotvrzeniOEvidenciNaUraduPrace = "PotvrzeniOEvidenciNaUraduPrace",
  PotvrzeniJinehoZamestnavateleOOdvoduZdravotnihoPojisteni = "PotvrzeniJinehoZamestnavateleOOdvoduZdravotnihoPojisteni",
  VymerMaterskeRodicovskeDovolene = "VymerMaterskeRodicovskeDovolene",
  VymerStarobnihoDuchodu = "VymerStarobnihoDuchodu",
  VymerInvalidnihoDuchodu12Stupne = "VymerInvalidnihoDuchodu12Stupne",
  VymerInvalidnihoDuchodu3Stupne = "VymerInvalidnihoDuchodu3Stupne",
  PrukazZTPP = "PrukazZTPP"
}
