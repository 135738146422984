import { InvalidDateIntervalError } from "../InvalidDateIntervalError";
import { PayPeriodInterval } from "../PayPeriodInterval";
import { WorkOffer } from "../WorkOffer";
import { CzechDocument } from "./CzechDocument";
import { CzechDocumentType } from "./CzechDocumentType";
import { Moment } from "moment-timezone";
import moment from "moment-timezone";

const timezone = "Europe/Prague";

function doDateRangesOverlap(dateAStart: Moment, dateAEnd: Moment, dateBStart: Moment, dateBEnd: Moment): boolean {
  return !(dateAEnd.isBefore(dateBStart, 'date') || dateAStart.isAfter(dateBEnd, 'date'));
}

export class CzechDocumentValidator {
  public static hasVerifiedDocumentOfTypeForPayInterval(verifiedDocuments: Omit<CzechDocument, 'employeeId'|'employerId'>[], payInterval: PayPeriodInterval, documentType: CzechDocumentType): boolean {
    return verifiedDocuments.some((document) => {
      if (document.type !== documentType) {
        return false;
      }
      return moment.tz(document.startsOn, timezone).isSameOrBefore(moment.tz(payInterval.startsOn, timezone), 'date')
        && moment.tz(payInterval.startsOn, timezone).isSameOrBefore(moment.tz(document.expiresOn, timezone), 'date');
    });
  }

  public static dayCountThatAnyOfTheseDocumentsIsValidInInterval(verifiedDocuments: Omit<CzechDocument, 'employeeId'|'employerId'>[], startDate: string, finishDate: string, documentTypes: CzechDocumentType[]): number {
    if (moment.tz(finishDate, timezone).isBefore(moment.tz(startDate, timezone))) {
      throw new InvalidDateIntervalError("Requested interval", startDate, finishDate);
    }
    const dayCountInInterval = moment.tz(finishDate, timezone).diff(moment.tz(startDate, timezone), "days") + 1;
    const verifiedDocumentsOfTypeInInterval = verifiedDocuments.filter((document) => {
      return documentTypes.includes(document.type) && doDateRangesOverlap(moment.tz(startDate, timezone), moment.tz(finishDate, timezone), moment.tz(document.startsOn, timezone),  moment.tz(document.expiresOn, timezone));
    });
    const days = Array.from({ length: dayCountInInterval }, (_: void, index: number) => index + 1);
    const daysWithValidDocuments = days.filter((day: number) => {
      return verifiedDocumentsOfTypeInInterval.some((document) => {
        const date = moment.tz(startDate, timezone).add(day - 1, 'day');
        const startsOn = moment.tz(document.startsOn, timezone);
        const expiresOn = moment.tz(document.expiresOn, timezone);
        return date.isBetween(startsOn, expiresOn, "date", "[]");
      });
    });
    return daysWithValidDocuments.length;
  }

  public static hasVerifiedDocumentOfTypeForWorkShift(verifiedDocuments: Omit<CzechDocument, 'employeeId'|'employerId'>[], workShift: WorkOffer, documentType: CzechDocumentType): boolean {
    return verifiedDocuments.some((document) => {
      if (document.type !== documentType) {
        return false;
      }
      return moment.tz(document.startsOn, timezone).isSameOrBefore(moment.tz(workShift.startTime, timezone), 'date') && moment.tz(workShift.startTime, timezone).isSameOrBefore(moment.tz(document.expiresOn, timezone), 'date');
    });

  }
}
