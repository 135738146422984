export class DateOutOfDateIntervalError extends Error {
  constructor(
    public readonly subject: string,
    public readonly invalidTime: string,
    public readonly intervalStartTime: string,
    public readonly intervalFinishTime: string,
  ) {
    super(`${subject}: Date (${invalidTime}) is not in required interval (from ${intervalStartTime} to ${intervalFinishTime}).`);
    Object.setPrototypeOf(this, DateOutOfDateIntervalError.prototype);
  }
}
