import { WorkShiftProvider } from './WorkShiftProvider';
import { WorkShift } from './WorkShift';
import moment from 'moment-timezone';
import { IntervalMatch } from './IntervalMatch';
import { WorkShiftFilters } from './WorkShiftFilters';
import { timezone } from './czechia/MomentTestSupport';

export class InMemoryWorkShiftProvider implements WorkShiftProvider {
  private records: WorkShift[] = [];

  public add(record: WorkShift): this {
    this.records.push(record);
    return this;
  }

  public addMany(records: WorkShift[]): this {
    for (const record of records) {
      this.add(record);
    }
    return this;
  }

  public async listForInterval(
    from: string,
    to: string,
    match: IntervalMatch,
    { contractIds, employeeId, employerId, status, type }: WorkShiftFilters = {}
  ): Promise<WorkShift[]> {
    const results: WorkShift[] = [];
    for (const record of this.records) {
      if (
        (contractIds && !contractIds.includes(record.contractId)) ||
        (employeeId !== undefined && employeeId !== record.employeeId) ||
        (employerId !== undefined && employerId !== record.employerId) ||
        (status && status !== record.status) ||
        (type && type !== record.type)
      ) {
        continue;
      }

      const workShiftStart = moment.tz(record.startTime, timezone);
      const workShiftFinish = moment.tz(record.finishTime, timezone);
      if (match === IntervalMatch.RecordBeginsInRequested) {
        if (workShiftStart.isBetween(from, to, undefined, '[]')) {
          results.push(record);
        }
      } else if (match === IntervalMatch.RecordOverlapsRequested) {
        if (
          workShiftStart.isBefore(to) &&
          workShiftFinish.isAfter(from)
        ) {
          results.push(record);
        }
      } else if (match === IntervalMatch.RecordTouchesRequested) {
        if (
          workShiftStart.isSameOrBefore(to) &&
          workShiftFinish.isSameOrAfter(from)
        ) {
          results.push(record);
        }
      }
    }
    return results;
  }
}
