import { PayPeriodInterval } from "./PayPeriodInterval";
import { WorkOffer } from "./WorkOffer";
import moment from "moment-timezone";

export class AgeCalculator {
  constructor(
    private readonly timezone: string,
  ) {
  }

  /**
   * Počítá věk zaměstnance k danému datumu.
   */
  public getAgeOnDate(date: string, dateOfBirth: string): number {
    const dateMoment = moment.tz(date, this.timezone);
    const dateOfBirthMoment = moment.tz(dateOfBirth.substring(0, 10), this.timezone); // vysvětlení viz Employee.dateOfBirth
    const years = dateMoment.year() - dateOfBirthMoment.year();
    const months = dateMoment.month() - dateOfBirthMoment.month();

    if (months < 0 || (months === 0 && dateMoment.date() < dateOfBirthMoment.date())) {
      return years - 1;
    }

    return years
  }

  /**
   * Počítá věk zaměstnance v daném výplatním období.
   */
  public getAgeInPayInterval(payInterval: PayPeriodInterval, dateOfBirth: string): number {
    return this.getAgeOnDate(payInterval.startsOn, dateOfBirth);
  }

  /**
   * Počítá věk zaměstnance v dané pracovní směně.
   */
  public getAgeAtStartOfWorkShift(workShift: WorkOffer, dateOfBirth: string): number {
    return this.getAgeOnDate(workShift.startTime, dateOfBirth);
  }
}
