import { EmployeeProvider } from "./EmployeeProvider";
import { Employee } from "./Employee";

export class InMemoryEmployeeProvider implements EmployeeProvider {
  private records: Record<string, Employee> = {};

  public add(record: Employee): this {
    this.records[record.id] = record;
    return this;
  }

  public addMany(records: Employee[]): this {
    for (const record of records) {
      this.add(record);
    }
    return this;
  }

  public async getById(id: string): Promise<Employee> {
    if (Object.prototype.hasOwnProperty.call(this.records, id)) {
      return this.records[id];
    }
    throw new Error(`Unknown employee '${id}'.`);
  }
}
