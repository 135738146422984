import type { EmployeeProvider } from "../EmployeeProvider";
import type { EmployerProvider } from "../EmployerProvider";
import type { CzechSalaryAdvanceProvider } from "./SalaryAdvanceProvider";
import type { WorkShiftProvider } from "../WorkShiftProvider";
import { CzechWorkCalculator } from "./CzechWorkCalculator";
import { CzechDocumentProvider } from "./CzechDocumentProvider";
import { CzechContractProvider } from "./CzechContractProvider";

export class CzechWorkCalculatorFactory {
  public create(
    employeeProvider: EmployeeProvider,
    employerProvider: EmployerProvider,
    salaryAdvanceProvider: CzechSalaryAdvanceProvider,
    workShiftProvider: WorkShiftProvider,
    documentProvider: CzechDocumentProvider,
    contractProvider: CzechContractProvider,
  ): CzechWorkCalculator {
    return new CzechWorkCalculator(
      employeeProvider,
      employerProvider,
      salaryAdvanceProvider,
      workShiftProvider,
      documentProvider,
      contractProvider,
    );
  }
}
