import moment from "moment-timezone";

export const timezone = "Europe/Prague";

export function newMoment(s: string|number[]): string {
  const m = moment.tz(s, timezone);
  if (!m.isValid()) { // safety measure against weirdly failing and/or passing tests
    throw new Error("This date is invalid: " + JSON.stringify(s));
  }
  return m.format(); // Udělá string v naší časové zóně.
}
