import { CzechSalaryAdvanceProvider } from './SalaryAdvanceProvider';
import { PayPeriodInterval } from '../PayPeriodInterval';
import { SalaryAdvanceFilters } from '../SalaryAdvanceFilters';
import { CzechSalaryAdvance } from './CzechSalaryAdvance';
import { CzechSalaryType } from './CzechSalaryType';
import moment from 'moment-timezone';
import { timezone } from './MomentTestSupport';

export class InMemorySalaryAdvanceProvider
  implements CzechSalaryAdvanceProvider
{
  private records: CzechSalaryAdvance[] = [];

  public add(record: CzechSalaryAdvance): this {
    this.records.push(record);
    return this;
  }

  public addMany(records: CzechSalaryAdvance[]): this {
    for (const record of records) {
      this.add(record);
    }
    return this;
  }

  public async listForInterval(
    interval: PayPeriodInterval,
    type: CzechSalaryType,
    { employeeId, employerId }: SalaryAdvanceFilters = {}
  ): Promise<CzechSalaryAdvance[]> {
    const results: CzechSalaryAdvance[] = [];
    for (const record of this.records) {
      if (
        (employeeId !== undefined && employeeId !== record.employeeId) ||
        (employerId !== undefined && employerId !== record.employerId) ||
        type !== record.type
      ) {
        continue;
      }

      const advancePaid = moment.tz(record.paidOn, timezone);
      const intervalEnds = moment
        .tz(interval.startsOn, timezone)
        .endOf('month');
      if (
        advancePaid.isBetween(interval.startsOn, intervalEnds, undefined, '[]')
      ) {
        results.push(record);
      }
    }
    return results;
  }
}
