import { WorkOffer } from "./WorkOffer";
import { WorkBreak } from "./WorkBreak";

export class WorkBreakOverlapError extends Error {
  public constructor(
    public readonly workShift: WorkOffer,
    public readonly workBreak1: WorkBreak,
    public readonly workBreak2: WorkBreak,
  ) {
    super(`Work breaks overlap (${workBreak1.startsAt}–${workBreak1.endsAt} with ${workBreak2.startsAt}–${workBreak2.endsAt}) on work at ${workShift.startTime} from ${workShift.employerId}`);
    Object.setPrototypeOf(this, WorkBreakOverlapError.prototype);
  }
}
