export class InvalidDateIntervalError extends Error {
  constructor(
    public readonly subject: string,
    public readonly startTime: string,
    public readonly finishTime: string,
  ) {
    super(`${subject}: Finish date (${finishTime}) is before start date (${startTime}).`);
    Object.setPrototypeOf(this, InvalidDateIntervalError.prototype);
  }
}
