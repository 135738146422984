export class IscoMismatchError extends Error {
  constructor(
    public readonly subject: string,
    public readonly iscoCode: string,
    public readonly iscoPrefixes: string[],
  ) {
    super(`${subject}: CZ-ISCO (${iscoCode}) is not covered by specified prefixes (${iscoPrefixes.join(',')}).`);
    Object.setPrototypeOf(this, IscoMismatchError.prototype);
  }
}
