import moment from "moment-timezone";
import { CzechContractProvider } from "./CzechContractProvider";
import { CzechContract } from "./CzechContract";
import { ContractValidityType } from "../ContractValidityType";
import { CzechContractFilters } from "./CzechContractFilters";
import { timezone } from "./MomentTestSupport";

export class InMemoryCzechContractProvider implements CzechContractProvider {
  private records: Record<string, CzechContract> = {};

  public add(record: CzechContract): this {
    this.records[record.id] = record;
    return this;
  }

  public addMany(records: CzechContract[]): this {
    for (const record of records) {
      this.add(record);
    }
    return this;
  }

  public getByIdSync(id: string): CzechContract {
    if (Object.prototype.hasOwnProperty.call(this.records, id)) {
      return this.records[id];
    }
    throw new Error(`Unknown contract '${id}'.`);
  }

  public async getById(id: string): Promise<CzechContract> {
    return this.getByIdSync(id);
  }

  public async listForInterval(
    from: string,
    to: string,
    validity: ContractValidityType,
    { employeeId, employerId, types }: CzechContractFilters = {}
  ): Promise<CzechContract[]> {
    const results: CzechContract[] = [];
    for (const record of Object.values(this.records)) {
      if (
        employeeId !== undefined && record.employeeId !== employeeId ||
        employerId !== undefined && record.employerId !== employerId ||
        types && !types.includes(record.type)
      ) {
        continue;
      }

      const contractStart = moment.tz(record.startsOn, timezone);
      const contractEnds = moment.tz(validity === ContractValidityType.Signed ? record.expiresOn : record.endsOn, timezone);
      if (contractStart.isSameOrBefore(to) && contractEnds.isSameOrAfter(from)) {
        results.push(record);
      }
    }
    return results;
  }
}
