import type { WorkShiftCheckFlags } from "./WorkShiftCheckFlags";

export class WorkShiftCheckIssue extends Error {
  public constructor(
    /** Kód problému. */
    public readonly flag: WorkShiftCheckFlags,
    /** Český popis problému. */
    public readonly description: string,
    /** Diagnostické informace. */
    public readonly extra: string,
    /** Výjimka, která problém způsobila. */
    public readonly cause?: Error,
  ) {
    // @ts-expect-error Node.js accepts second argument
    super(`[${flag}] ${description}; extra: ${extra}`, { cause });
    Object.setPrototypeOf(this, WorkShiftCheckIssue.prototype);
  }
}
