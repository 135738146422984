import moment from 'moment-timezone';
import { DocumentFilters } from '../DocumentFilters';
import { CzechDocument } from './CzechDocument';
import { CzechDocumentProvider } from './CzechDocumentProvider';
import { timezone } from './MomentTestSupport';

export class InMemoryCzechDocumentProvider implements CzechDocumentProvider {
  private records: CzechDocument[] = [];

  public add(record: CzechDocument): this {
    this.records.push(record);
    return this;
  }

  public addMany(records: CzechDocument[]): this {
    for (const record of records) {
      this.add(record);
    }
    return this;
  }

  public async listForInterval(
    from: string,
    to: string,
    { employeeId, employerId }: DocumentFilters = {}
  ): Promise<CzechDocument[]> {
    const results: CzechDocument[] = [];
    for (const record of this.records) {
      if (
        (employeeId !== undefined && employeeId !== record.employeeId) ||
        (employerId !== undefined && record.employerId && employerId !== record.employerId)
      ) {
        continue;
      }

      const documentStarts = moment.tz(record.startsOn, timezone);
      const documentExpires = moment.tz(record.expiresOn, timezone);
      if (
        documentStarts.isSameOrBefore(to) &&
        documentExpires.isSameOrAfter(from)
      ) {
        results.push(record);
      }
    }
    return results;
  }
}
